<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <!--  -->
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <DownloadExcel
                    :url="state.endpoint"
                    :params="state.params"
                    namefile="Summary-Survey"
                    @errors="errorMessage"/>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                row-key="id"
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>
                
            </MdTable>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import { pickBy } from 'lodash'
import moment from 'moment'

export default defineComponent({
    components: {
        DownloadExcel,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'ID Region',
                    dataIndex: 'field',
                    customRender({ text, record }) {
                        return {
                            children: text,
                            attrs: {
                                rowSpan: record.RegionRowSpan > 0 ? record.RegionRowSpan: 0,
                            },
                        }
                    },
                },
                {
                    title: 'Provinsi',
                    dataIndex: 'field',
                    customRender({ text, record }) {
                        return {
                            children: text,
                            attrs: {
                                rowSpan: record.ProvinsiRowSpan > 0 ? record.ProvinsiRowSpan: 0,
                            },
                        }
                    },
                },
                {
                    title: 'Nama Area',
                    dataIndex: 'field',
                    customRender({ text, record }) {
                        return {
                            children: text,
                            attrs: {
                                rowSpan: record.NameAreaRowSpan > 0 ? record.NameAreaRowSpan: 0,
                            },
                        }
                    },
                },
                {
                    title: 'Nama Distrik',
                    dataIndex: 'field',
                    customRender({ text, record }) {
                        return {
                            children: text,
                            attrs: {
                                rowSpan: record.NameDistrikRowSpan > 0 ? record.NameDistrikRowSpan: 0,
                            },
                        }
                    },
                },
                {
                    title: 'Nama Produk',
                    dataIndex: 'field',
                },
                {
                    title: 'Min of Harga Pembelian',
                    dataIndex: 'field',
                },
                {
                    title: 'Max of Harga Pembelian',
                    dataIndex: 'field',
                },
                {
                    title: 'Min of Harga Penjualan',
                    dataIndex: 'field',
                },
                {
                    title: 'Max of Harga Penjualan',
                    dataIndex: 'field',
                },
                {
                    title: 'Promo',
                    dataIndex: 'field',
                },
                {
                    title: 'Keluhan',
                    dataIndex: 'field',
                },
            ],
            endpoint:'/api/unknown',
            data: [],
            meta: {
                per_page: 30,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isDownloading: false,
            params: {
                q: '',
                page: 1,
                "per-page": 10,
            },
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.start_date) {
                state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
            }

            if (state.params.end_date) {
                state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
            }

            return pickBy(params)
        }

        const mapDataWithRowSpan = (data) => {
            return data.map((item, index, array) => {
                const prevItem = array[index - 1];
                const regionRowSpan = index === 0 || item.Field1 !== prevItem.Field1
                    ? array.filter(d => d.Field1 === item.Field1).length
                    : 0;
                const provinsiRowSpan = index === 0 || item.Field2 !== prevItem.Field2
                    ? array.filter(d => d.Field2 === item.Field2).length
                    : 0;
                const nameAreaRowSpan = index === 0 || item.Field3 !== prevItem.Field3
                    ? array.filter(d => d.Field3 === item.Field3).length
                    : 0;
                const nameDistrikRowSpan = index === 0 || item.Field4 !== prevItem.Field4
                    ? array.filter(d => d.Field4 === item.Field4).length
                    : 0;

                return {
                    ...item,
                    RegionRowSpan: regionRowSpan,
                    ProvinsiRowSpan: provinsiRowSpan,
                    NameAreaRowSpan: nameAreaRowSpan,
                    NameDistrikRowSpan: nameDistrikRowSpan,
                }
            })
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get(state.endpoint, {
                    params: pickBy(state.params),
                })
                .then(({ data }) => {
                    const { items, _meta } = data
                    state.data = mapDataWithRowSpan(items)
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            state,
            fetchDataList,
            handleTableChange,
            errorMessage,
            queryParams,
        }
    },
})
</script>
